import React, { useEffect, useState } from "react";
import Home from "../../Components/Home/HomeSection/Home";
import { Helmet } from "react-helmet";
import OnDemandLoader from "../../Components/Home/OnDemandLoader";
import scriptLoader from "../../utils/javascriptsLoader";
import "react-loading-skeleton/dist/skeleton.css";

const UserHome = (props) => {
  const [loadedComponentsCount, setLoadedComponentsCount] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [shouldStartLoading, setShouldStartLoading] = useState(false);
  useEffect(() => {
    const handleWindowLoad = () => {
      setTimeout(() => {
        setShouldStartLoading(true);
      }, 5000);
    };
    if (document.readyState === "complete") {
      // COMPONENTS TOTAL
      handleWindowLoad();
    } else {
      window.addEventListener("load", handleWindowLoad);
    }

    return () => {
      window.removeEventListener("load", handleWindowLoad);
    };
  }, [loadedComponentsCount]);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  const addToList = () => {
    setLoadedComponentsCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 8) {
        loadAppCarousel();
        if (props.location.hash !== "") {
          setTimeout(() => {
            document
              .getElementById(props.location.hash.replace("#", ""))
              ?.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 1500);
        }
      }
      return newCount;
    });
  };

  const loadAppCarousel = () => {
    const technologiesScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/technologies.js?ca93809a-cd54-40d0-ad2c-a763aba41857",
      async: 1,
      defer: 1,
      id: "technologiesId",
    };
    scriptLoader(technologiesScript);
    const servicesScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/services.js?ca93809a-cd54-40d0-ad2c-a763aba41856",
      async: 1,
      defer: 1,
      id: "technologiesId",
    };
    scriptLoader(servicesScript);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>fxis.ai | The Original AI Company</title>
        <meta
          name="description"
          content="fxis.ai is a global leader in advanced AI and data science technologies, including generative AI, large language models (LLMs), and machine learning, to drive business transformation. As a trusted partner to leading companies globally, we specialize in solutions that enhance efficiency and catalyze change."
        />
      </Helmet>
      <Home
        isVideoLoaded={isVideoLoaded}
        shouldStartLoading={shouldStartLoading}
        handleVideoLoaded={handleVideoLoaded}
      />
      <OnDemandLoader {...props} addToList={addToList} />
    </React.Fragment>
  );
};

export default UserHome;
