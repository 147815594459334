import ProjectOutsourcing from "../Components/Home/ServiceSection/ProjectOutsourcing";
import OnDemandDe from "../Components/Home/ServiceSection/OnDemandDev";
import TechandTalent from "../Components/Home/ServiceSection/TechandTalent";
export const DEFAULT_TABS = [
  // {
  //     id: 1,
  //     title: 'Home',
  //     path: '#home',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 2,
  //     title: 'About Us',
  //     path: '#about',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 3,
  //     title: 'Services',
  //     path: '#services',
  //     div_id: 's_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'service_dropdown_toggle',
  //     subTabs: [
  //         {title: 'Services We Offer', path: '#services', partialParentPath: '/'},
  //         {title: 'Industries We Work In', path: '#industries', partialParentPath: '/'},
  //         {title: ' Technologies We Use', path: '#technologies', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 4,
  //     title: 'Products',
  //     path: '#products',
  //     div_id: 'p_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'product_dropdown_toggle',
  //     subTabs: [
  //         {title: 'H+Tree', path: '#p_hplus_tree', partialParentPath: '/'},
  //         {title: 'F(x) Data Cloud', path: '#p_fx_data_clouds', partialParentPath: '/'},
  //         {title: 'F(x) Optimizer', path: '#p_fx_optimizer', partialParentPath: '/'},
  //         {title: 'F(x) ERP', path: '#p_fx_erp', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 5,
  //     title: 'Contact Us',
  //     path: '#contact',
  //     partialParentPath: '/'
  // },
];

export const PHASE_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "rejected",
  4: "completed",
};

export const TIMELINE_TAIL_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "pending",
  4: "completed",
};

export const USER_PROGRESS_STATUS_CLASSES = {
  1: "secondary",
  2: "success",
  3: "danger",
  4: "light",
};

export const MAX_FILE_UPLOAD_SIZE = 50;

export const MAX_PAGINATION_ITEMS = 5;

export const DEVELOPER_PROCESS_STATUS = [
  { name: "In Process", value: 2 },
  { name: "Rejected", value: 3 },
  { name: "Completed", value: 4 },
];

export const OurCultureData = [
  {
    desc: "ourculture.desc1",
    title: "ourculture.title1",
    image: "../images/ourculture/GlobalCulture.webp",
  },
  {
    desc: "ourculture.desc2",
    title: "ourculture.title2",
    image: "../images/ourculture/WokeCulture.webp",
  },
  {
    desc: "ourculture.desc3",
    title: "ourculture.title3",
    image: "../images/ourculture/RadicalCondor.webp",
  },
  {
    desc: "ourculture.desc4",
    title: "ourculture.title4",
    image: "../images/ourculture/FriendlyWorkCulture.webp",
  },
  {
    desc: "ourculture.desc5",
    title: "ourculture.title5",
    image: "../images/ourculture/HighPerformance.webp",
  },
  {
    desc: "ourculture.desc6",
    title: "ourculture.title6",
    image: "../images/ourculture/HardWorkingTeam.webp",
  },
]
export const HireExExpertsCompany = [
  {
    id: 0,
    row_1_images: [
      "../images/hireexexperts/slide_1/1_row/1.webp",
      "../images/hireexexperts/slide_1/1_row/2.webp",
      "../images/hireexexperts/slide_1/1_row/6.webp",
      "../images/hireexexperts/slide_1/1_row/7.webp",
      "../images/hireexexperts/slide_1/1_row/7203.webp",
      "../images/hireexexperts/slide_1/2_row/7204.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_1/2_row/7205.webp",
      "../images/hireexexperts/slide_1/2_row/7206.webp",
      "../images/hireexexperts/slide_1/2_row/7207.webp",
      "../images/hireexexperts/slide_1/2_row/7208.webp",
      "../images/hireexexperts/slide_2/1_row/2.webp",
      "../images/hireexexperts/slide_2/1_row/1.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_2/1_row/7212.webp",
      "../images/hireexexperts/slide_2/1_row/3.webp",
      "../images/hireexexperts/slide_2/1_row/7209.webp",
      "../images/hireexexperts/slide_2/2_row/5.webp",
      "../images/hireexexperts/slide_2/2_row/7205.webp",
      "../images/hireexexperts/slide_2/2_row/4.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_2/2_row/7210.webp",
      "../images/hireexexperts/slide_2/2_row/7211.webp",
      "../images/hireexexperts/slide_3/1_row/7212.webp",
      "../images/hireexexperts/slide_3/1_row/1.webp",
      "../images/hireexexperts/slide_3/1_row/7208.webp",
      "../images/hireexexperts/slide_3/1_row/4.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_3/1_row/7214.webp",
      "../images/hireexexperts/slide_3/2_row/2.webp",
      "../images/hireexexperts/slide_3/2_row/3.webp",
      "../images/hireexexperts/slide_3/2_row/4.webp",
      "../images/hireexexperts/slide_3/2_row/5.webp",
      "../images/hireexexperts/slide_3/2_row/7215.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_4/1_row/7217.webp",
      "../images/hireexexperts/slide_4/1_row/7225.webp",
      "../images/hireexexperts/slide_4/1_row/7218.webp",
      "../images/hireexexperts/slide_4/1_row/7219.webp",
      "../images/hireexexperts/slide_4/1_row/1.webp",
      "../images/hireexexperts/slide_4/2_row/7220.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_4/2_row/7221.webp",
      "../images/hireexexperts/slide_4/2_row/7222.webp",
      "../images/hireexexperts/slide_4/2_row/7223.webp",
      "../images/hireexexperts/slide_4/2_row/7224.webp",
      "../images/hireexexperts/slide_5/1_row/7221.webp",
      "../images/hireexexperts/slide_5/1_row/7220.webp",
    ],

    row_2_images: [],

    row_3_images: [],
  },
];

export const HireExExpertsCompanyTablet = [
  {
    id: 0,
    row_1_images: [
      "../images/hireexexperts/slide_1/1_row/1.webp",
      "../images/hireexexperts/slide_1/1_row/2.webp",
      "../images/hireexexperts/slide_1/1_row/6.webp",
      "../images/hireexexperts/slide_1/1_row/7.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_1/1_row/7203.webp",
      "../images/hireexexperts/slide_1/2_row/7204.webp",
      "../images/hireexexperts/slide_1/2_row/7205.webp",
      "../images/hireexexperts/slide_1/2_row/7206.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_1/2_row/7207.webp",
      "../images/hireexexperts/slide_1/2_row/7208.webp",
      "../images/hireexexperts/slide_2/1_row/2.webp",
      "../images/hireexexperts/slide_2/1_row/1.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_2/1_row/7212.webp",
      "../images/hireexexperts/slide_2/1_row/3.webp",
      "../images/hireexexperts/slide_2/1_row/7209.webp",
      "../images/hireexexperts/slide_2/2_row/5.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_2/2_row/7205.webp",
      "../images/hireexexperts/slide_2/2_row/4.webp",
      "../images/hireexexperts/slide_2/2_row/7210.webp",
      "../images/hireexexperts/slide_2/2_row/7211.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_3/1_row/7212.webp",
      "../images/hireexexperts/slide_3/1_row/1.webp",
      "../images/hireexexperts/slide_3/1_row/7208.webp",
      "../images/hireexexperts/slide_3/1_row/4.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/hireexexperts/slide_3/1_row/7214.webp",
      "../images/hireexexperts/slide_3/2_row/2.webp",
      "../images/hireexexperts/slide_3/2_row/3.webp",
      "../images/hireexexperts/slide_3/2_row/4.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_3/2_row/5.webp",
      "../images/hireexexperts/slide_3/2_row/7215.webp",
      "../images/hireexexperts/slide_4/1_row/7217.webp",
      "../images/hireexexperts/slide_4/1_row/7225.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_4/1_row/7218.webp",
      "../images/hireexexperts/slide_4/1_row/7219.webp",
      "../images/hireexexperts/slide_4/1_row/1.webp",
      "../images/hireexexperts/slide_4/2_row/7220.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/hireexexperts/slide_4/2_row/7221.webp",
      "../images/hireexexperts/slide_4/2_row/7222.webp",
      "../images/hireexexperts/slide_4/2_row/7223.webp",
      "../images/hireexexperts/slide_4/2_row/7224.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_5/1_row/7221.webp",
      "../images/hireexexperts/slide_5/1_row/7220.webp",
      "../images/hireexexperts/slide_5/1_row/7222.webp",
      "../images/hireexexperts/slide_5/1_row/7223.webp",
    ],

    row_3_images: ["../images/hireexexperts/slide_5/1_row/7224.webp"],
  },
];

export const HireExExpertsCompanyMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/hireexexperts/slide_1/1_row/1.webp",
      "../images/hireexexperts/slide_1/1_row/2.webp",
      "../images/hireexexperts/slide_1/1_row/6.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_1/1_row/7.webp",
      "../images/hireexexperts/slide_1/1_row/7203.webp",
      "../images/hireexexperts/slide_1/2_row/7204.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_1/2_row/7205.webp",
      "../images/hireexexperts/slide_1/2_row/7206.webp",
      "../images/hireexexperts/slide_1/2_row/7207.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_1/2_row/7208.webp",
      "../images/hireexexperts/slide_2/1_row/2.webp",
      "../images/hireexexperts/slide_2/1_row/1.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_2/1_row/7212.webp",
      "../images/hireexexperts/slide_2/1_row/3.webp",
      "../images/hireexexperts/slide_2/1_row/7209.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_2/2_row/5.webp",
      "../images/hireexexperts/slide_2/2_row/7205.webp",
      "../images/hireexexperts/slide_2/2_row/4.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/hireexexperts/slide_2/2_row/7210.webp",
      "../images/hireexexperts/slide_2/2_row/7211.webp",
      "../images/hireexexperts/slide_3/1_row/7212.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_3/1_row/1.webp",
      "../images/hireexexperts/slide_3/1_row/7208.webp",
      "../images/hireexexperts/slide_3/1_row/4.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_3/1_row/7214.webp",
      "../images/hireexexperts/slide_3/2_row/2.webp",
      "../images/hireexexperts/slide_3/2_row/3.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/hireexexperts/slide_3/2_row/4.webp",
      "../images/hireexexperts/slide_3/2_row/5.webp",
      "../images/hireexexperts/slide_3/2_row/7215.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_4/1_row/7217.webp",
      "../images/hireexexperts/slide_4/1_row/7225.webp",
      "../images/hireexexperts/slide_4/1_row/7218.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_4/1_row/7219.webp",
      "../images/hireexexperts/slide_4/1_row/1.webp",
      "../images/hireexexperts/slide_4/2_row/7220.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/hireexexperts/slide_4/2_row/7221.webp",
      "../images/hireexexperts/slide_4/2_row/7222.webp",
      "../images/hireexexperts/slide_4/2_row/7223.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_4/2_row/7224.webp",
      "../images/hireexexperts/slide_5/1_row/7221.webp",
      "../images/hireexexperts/slide_5/1_row/7220.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_5/1_row/7222.webp",
      "../images/hireexexperts/slide_5/1_row/7223.webp",
      "../images/hireexexperts/slide_5/1_row/7224.webp",
    ],
  },
];

export const TechStackFullStack = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/FullStack/1.webp",
      "../images/TechStack/FullStack/2.webp",
      "../images/TechStack/FullStack/3.webp",
      "../images/TechStack/FullStack/4.webp",
      "../images/TechStack/FullStack/5.webp",
      "../images/TechStack/FullStack/6.webp",
      "../images/TechStack/FullStack/7.webp",
      "../images/TechStack/FullStack/8.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/9.webp",
      "../images/TechStack/FullStack/10.webp",
      "../images/TechStack/FullStack/11.webp",
      "../images/TechStack/FullStack/12.webp",
      "../images/TechStack/FullStack/13.webp",
      "../images/TechStack/FullStack/14.webp",
      "../images/TechStack/FullStack/15.webp",
      "../images/TechStack/FullStack/16.webp",
    ],

    row_3_images: [
      "../images/TechStack/FullStack/17.webp",
      "../images/TechStack/FullStack/18.webp",
      "../images/TechStack/FullStack/19.webp",
      "../images/TechStack/FullStack/20.webp",
      "../images/TechStack/FullStack/21.webp",
      "../images/TechStack/FullStack/22.webp",
      "../images/TechStack/FullStack/23.webp",
      "../images/TechStack/FullStack/24.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/FullStack/25.webp",
      "../images/TechStack/FullStack/26.webp",
      "../images/TechStack/FullStack/27.webp",
      "../images/TechStack/FullStack/28.webp",
      "../images/TechStack/FullStack/29.webp",
      "../images/TechStack/FullStack/30.webp",
      "../images/TechStack/FullStack/31.webp",
      "../images/TechStack/FullStack/32.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/33.webp",
      "../images/TechStack/FullStack/34.webp",
      "../images/TechStack/FullStack/35.webp",
      "../images/TechStack/FullStack/36.webp",
      "../images/TechStack/FullStack/37.webp",
      "../images/TechStack/FullStack/38.webp",
      "../images/TechStack/FullStack/39.webp",
      "../images/TechStack/FullStack/40.webp",
    ],

    row_3_images: ["../images/TechStack/FullStack/41.webp"],
  },
];

export const TechStackFullStackTablet = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/FullStack/1.webp",
      "../images/TechStack/FullStack/2.webp",
      "../images/TechStack/FullStack/3.webp",
      "../images/TechStack/FullStack/4.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/5.webp",
      "../images/TechStack/FullStack/6.webp",
      "../images/TechStack/FullStack/7.webp",
      "../images/TechStack/FullStack/8.webp",
    ],

    row_3_images: [
      "../images/TechStack/FullStack/9.webp",
      "../images/TechStack/FullStack/10.webp",
      "../images/TechStack/FullStack/11.webp",
      "../images/TechStack/FullStack/12.webp",
    ],
    row_4_images: [
      "../images/TechStack/FullStack/13.webp",
      "../images/TechStack/FullStack/14.webp",
      "../images/TechStack/FullStack/15.webp",
      "../images/TechStack/FullStack/16.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/FullStack/17.webp",
      "../images/TechStack/FullStack/18.webp",
      "../images/TechStack/FullStack/19.webp",
      "../images/TechStack/FullStack/20.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/21.webp",
      "../images/TechStack/FullStack/22.webp",
      "../images/TechStack/FullStack/23.webp",
      "../images/TechStack/FullStack/24.webp",
    ],

    row_3_images: [
      "../images/TechStack/FullStack/25.webp",
      "../images/TechStack/FullStack/26.webp",
      "../images/TechStack/FullStack/27.webp",
      "../images/TechStack/FullStack/28.webp",
    ],
    row_4_images: [
      "../images/TechStack/FullStack/29.webp",
      "../images/TechStack/FullStack/30.webp",
      "../images/TechStack/FullStack/31.webp",
      "../images/TechStack/FullStack/32.webp",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "../images/TechStack/FullStack/33.webp",
      "../images/TechStack/FullStack/34.webp",
      "../images/TechStack/FullStack/35.webp",
      "../images/TechStack/FullStack/36.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/37.webp",
      "../images/TechStack/FullStack/38.webp",
      "../images/TechStack/FullStack/39.webp",
      "../images/TechStack/FullStack/40.webp",
    ],

    row_3_images: ["../images/TechStack/FullStack/41.webp"],
    row_4_images: [],
  },
];

export const TechStackFullStackMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/FullStack/1.webp",
      "../images/TechStack/FullStack/2.webp",
      "../images/TechStack/FullStack/3.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/4.webp",
      "../images/TechStack/FullStack/5.webp",
      "../images/TechStack/FullStack/6.webp",
    ],

    row_3_images: [
      "../images/TechStack/FullStack/7.webp",
      "../images/TechStack/FullStack/8.webp",
      "../images/TechStack/FullStack/9.webp",
    ],
    row_4_images: [
      "../images/TechStack/FullStack/10.webp",
      "../images/TechStack/FullStack/11.webp",
      "../images/TechStack/FullStack/12.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/FullStack/13.webp",
      "../images/TechStack/FullStack/14.webp",
      "../images/TechStack/FullStack/15.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/16.webp",
      "../images/TechStack/FullStack/17.webp",
      "../images/TechStack/FullStack/18.webp",
    ],

    row_3_images: [
      "../images/TechStack/FullStack/19.webp",
      "../images/TechStack/FullStack/20.webp",
      "../images/TechStack/FullStack/21.webp",
    ],
    row_4_images: [
      "../images/TechStack/FullStack/22.webp",
      "../images/TechStack/FullStack/23.webp",
      "../images/TechStack/FullStack/24.webp",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "../images/TechStack/FullStack/25.webp",
      "../images/TechStack/FullStack/26.webp",
      "../images/TechStack/FullStack/27.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/28.webp",
      "../images/TechStack/FullStack/29.webp",
      "../images/TechStack/FullStack/30.webp",
    ],

    row_3_images: [
      "../images/TechStack/FullStack/31.webp",
      "../images/TechStack/FullStack/32.webp",
      "../images/TechStack/FullStack/33.webp",
    ],
    row_4_images: [
      "../images/TechStack/FullStack/34.webp",
      "../images/TechStack/FullStack/35.webp",
      "../images/TechStack/FullStack/36.webp",
    ],
  },

  {
    id: 3,
    row_1_images: [
      "../images/TechStack/FullStack/37.webp",
      "../images/TechStack/FullStack/38.webp",
      "../images/TechStack/FullStack/39.webp",
    ],

    row_2_images: [
      "../images/TechStack/FullStack/40.webp",
      "../images/TechStack/FullStack/41.webp",
    ],

    row_3_images: [],
    row_4_images: [],
  },
];

export const TechStackDataScience = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/DataScience/1.webp",
      "../images/TechStack/DataScience/2.webp",
      "../images/TechStack/DataScience/3.webp",
      "../images/TechStack/DataScience/4.webp",
      "../images/TechStack/DataScience/5.webp",
      "../images/TechStack/DataScience/6.webp",
      "../images/TechStack/DataScience/7.webp",
      "../images/TechStack/DataScience/8.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/9.webp",
      "../images/TechStack/DataScience/10.webp",
      "../images/TechStack/DataScience/11.webp",
      "../images/TechStack/DataScience/12.webp",
      "../images/TechStack/DataScience/13.webp",
      "../images/TechStack/DataScience/14.webp",
      "../images/TechStack/DataScience/15.webp",
      "../images/TechStack/DataScience/16.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/17.webp",
      "../images/TechStack/DataScience/18.webp",
      "../images/TechStack/DataScience/19.webp",
      "../images/TechStack/DataScience/20.webp",
      "../images/TechStack/DataScience/21.webp",
      "../images/TechStack/DataScience/22.webp",
      "../images/TechStack/DataScience/23.webp",
      "../images/TechStack/DataScience/24.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/DataScience/25.webp",
      "../images/TechStack/DataScience/26.webp",
      "../images/TechStack/DataScience/27.webp",
      "../images/TechStack/DataScience/28.webp",
      "../images/TechStack/DataScience/29.webp",
      "../images/TechStack/DataScience/30.webp",
      "../images/TechStack/DataScience/31.webp",
      "../images/TechStack/DataScience/32.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/33.webp",
      "../images/TechStack/DataScience/34.webp",
      "../images/TechStack/DataScience/35.webp",
      "../images/TechStack/DataScience/36.webp",
      "../images/TechStack/DataScience/37.webp",
      "../images/TechStack/DataScience/38.webp",
      "../images/TechStack/DataScience/39.webp",
      "../images/TechStack/DataScience/40.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/41.webp",
      "../images/TechStack/DataScience/42.webp",
      "../images/TechStack/DataScience/43.webp",
      "../images/TechStack/DataScience/44.webp",
      "../images/TechStack/DataScience/45.webp",
      "../images/TechStack/DataScience/46.webp",
      "../images/TechStack/DataScience/47.webp",
      "../images/TechStack/DataScience/48.webp",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "../images/TechStack/DataScience/49.webp",
      "../images/TechStack/DataScience/50.webp",
      "../images/TechStack/DataScience/51.webp",
      "../images/TechStack/DataScience/52.webp",
      "../images/TechStack/DataScience/53.webp",
      "../images/TechStack/DataScience/54.webp",
      "../images/TechStack/DataScience/55.webp",
      "../images/TechStack/DataScience/56.webp",
    ],
    row_2_images: [
      "../images/TechStack/DataScience/57.webp",
      "../images/TechStack/DataScience/58.webp",
      "../images/TechStack/DataScience/59.webp",
      "../images/TechStack/DataScience/60.webp",
      "../images/TechStack/DataScience/61.webp",
      "../images/TechStack/DataScience/62.webp",
      "../images/TechStack/DataScience/63.webp",
      "../images/TechStack/DataScience/64.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/65.webp",
      "../images/TechStack/DataScience/66.webp",
      "../images/TechStack/DataScience/67.webp",
    ],
  },
];

export const TechStackDataScienceTablet = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/DataScience/1.webp",
      "../images/TechStack/DataScience/2.webp",
      "../images/TechStack/DataScience/3.webp",
      "../images/TechStack/DataScience/4.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/5.webp",
      "../images/TechStack/DataScience/6.webp",
      "../images/TechStack/DataScience/7.webp",
      "../images/TechStack/DataScience/8.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/9.webp",
      "../images/TechStack/DataScience/10.webp",
      "../images/TechStack/DataScience/11.webp",
      "../images/TechStack/DataScience/12.webp",
    ],

    row_4_images: [
      "../images/TechStack/DataScience/13.webp",
      "../images/TechStack/DataScience/14.webp",
      "../images/TechStack/DataScience/15.webp",
      "../images/TechStack/DataScience/16.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/DataScience/17.webp",
      "../images/TechStack/DataScience/18.webp",
      "../images/TechStack/DataScience/19.webp",
      "../images/TechStack/DataScience/20.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/21.webp",
      "../images/TechStack/DataScience/22.webp",
      "../images/TechStack/DataScience/23.webp",
      "../images/TechStack/DataScience/24.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/25.webp",
      "../images/TechStack/DataScience/26.webp",
      "../images/TechStack/DataScience/27.webp",
      "../images/TechStack/DataScience/28.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/29.webp",
      "../images/TechStack/DataScience/30.webp",
      "../images/TechStack/DataScience/31.webp",
      "../images/TechStack/DataScience/32.webp",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "../images/TechStack/DataScience/33.webp",
      "../images/TechStack/DataScience/34.webp",
      "../images/TechStack/DataScience/35.webp",
      "../images/TechStack/DataScience/36.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/37.webp",
      "../images/TechStack/DataScience/38.webp",
      "../images/TechStack/DataScience/39.webp",
      "../images/TechStack/DataScience/40.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/41.webp",
      "../images/TechStack/DataScience/42.webp",
      "../images/TechStack/DataScience/43.webp",
      "../images/TechStack/DataScience/44.webp",
    ],

    row_4_images: [
      "../images/TechStack/DataScience/45.webp",
      "../images/TechStack/DataScience/46.webp",
      "../images/TechStack/DataScience/47.webp",
      "../images/TechStack/DataScience/48.webp",
    ],
  },

  {
    id: 3,
    row_1_images: [
      "../images/TechStack/DataScience/49.webp",
      "../images/TechStack/DataScience/50.webp",
      "../images/TechStack/DataScience/51.webp",
      "../images/TechStack/DataScience/52.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/53.webp",
      "../images/TechStack/DataScience/54.webp",
      "../images/TechStack/DataScience/55.webp",
      "../images/TechStack/DataScience/56.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/57.webp",
      "../images/TechStack/DataScience/58.webp",
      "../images/TechStack/DataScience/59.webp",
      "../images/TechStack/DataScience/60.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/61.webp",
      "../images/TechStack/DataScience/62.webp",
      "../images/TechStack/DataScience/63.webp",
      "../images/TechStack/DataScience/64.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/TechStack/DataScience/65.webp",
      "../images/TechStack/DataScience/66.webp",
      "../images/TechStack/DataScience/67.webp",
    ],

    row_2_images: [],

    row_3_images: [],
    row_4_images: [],
  },
];

export const TechStackDataScienceMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/DataScience/1.webp",
      "../images/TechStack/DataScience/2.webp",
      "../images/TechStack/DataScience/3.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/4.webp",
      "../images/TechStack/DataScience/5.webp",
      "../images/TechStack/DataScience/6.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/7.webp",
      "../images/TechStack/DataScience/8.webp",
      "../images/TechStack/DataScience/9.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/10.webp",
      "../images/TechStack/DataScience/11.webp",
      "../images/TechStack/DataScience/12.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/DataScience/13.webp",
      "../images/TechStack/DataScience/14.webp",
      "../images/TechStack/DataScience/15.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/16.webp",
      "../images/TechStack/DataScience/17.webp",
      "../images/TechStack/DataScience/18.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/19.webp",
      "../images/TechStack/DataScience/20.webp",
      "../images/TechStack/DataScience/21.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/22.webp",
      "../images/TechStack/DataScience/23.webp",
      "../images/TechStack/DataScience/24.webp",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "../images/TechStack/DataScience/25.webp",
      "../images/TechStack/DataScience/26.webp",
      "../images/TechStack/DataScience/27.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/28.webp",
      "../images/TechStack/DataScience/29.webp",
      "../images/TechStack/DataScience/30.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/31.webp",
      "../images/TechStack/DataScience/32.webp",
      "../images/TechStack/DataScience/33.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/34.webp",
      "../images/TechStack/DataScience/35.webp",
      "../images/TechStack/DataScience/36.webp",
    ],
  },

  {
    id: 3,
    row_1_images: [
      "../images/TechStack/DataScience/37.webp",
      "../images/TechStack/DataScience/38.webp",
      "../images/TechStack/DataScience/39.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/40.webp",
      "../images/TechStack/DataScience/41.webp",
      "../images/TechStack/DataScience/42.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/43.webp",
      "../images/TechStack/DataScience/44.webp",
      "../images/TechStack/DataScience/45.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/46.webp",
      "../images/TechStack/DataScience/47.webp",
      "../images/TechStack/DataScience/48.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/TechStack/DataScience/49.webp",
      "../images/TechStack/DataScience/50.webp",
      "../images/TechStack/DataScience/51.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/52.webp",
      "../images/TechStack/DataScience/53.webp",
      "../images/TechStack/DataScience/54.webp",
    ],

    row_3_images: [
      "../images/TechStack/DataScience/55.webp",
      "../images/TechStack/DataScience/56.webp",
      "../images/TechStack/DataScience/57.webp",
    ],
    row_4_images: [
      "../images/TechStack/DataScience/58.webp",
      "../images/TechStack/DataScience/59.webp",
      "../images/TechStack/DataScience/60.webp",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "../images/TechStack/DataScience/61.webp",
      "../images/TechStack/DataScience/62.webp",
      "../images/TechStack/DataScience/63.webp",
    ],

    row_2_images: [
      "../images/TechStack/DataScience/64.webp",
      "../images/TechStack/DataScience/65.webp",
      "../images/TechStack/DataScience/66.webp",
    ],

    row_3_images: ["../images/TechStack/DataScience/67.webp"],
    row_4_images: [],
  },
];

export const TechStackDevOps = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/DevOps/1.webp",
      "../images/TechStack/DevOps/2.webp",
      "../images/TechStack/DevOps/3.webp",
      "../images/TechStack/DevOps/4.webp",
      "../images/TechStack/DevOps/5.webp",
      "../images/TechStack/DevOps/6.webp",
      "../images/TechStack/DevOps/7.webp",
      "../images/TechStack/DevOps/8.webp",
    ],

    row_2_images: [
      "../images/TechStack/DevOps/9.webp",
      "../images/TechStack/DevOps/10.webp",
      "../images/TechStack/DevOps/11.webp",
      "../images/TechStack/DevOps/12.webp",
      "../images/TechStack/DevOps/13.webp",
      "../images/TechStack/DevOps/14.webp",
      "../images/TechStack/DevOps/15.webp",
      "../images/TechStack/DevOps/16.webp",
    ],

    row_3_images: ["../images/TechStack/DevOps/17.webp"],
  },
];

export const TechStackDevOpsTablet = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/DevOps/1.webp",
      "../images/TechStack/DevOps/2.webp",
      "../images/TechStack/DevOps/3.webp",
      "../images/TechStack/DevOps/4.webp",
    ],

    row_2_images: [
      "../images/TechStack/DevOps/5.webp",
      "../images/TechStack/DevOps/6.webp",
      "../images/TechStack/DevOps/7.webp",
      "../images/TechStack/DevOps/8.webp",
    ],

    row_3_images: [
      "../images/TechStack/DevOps/9.webp",
      "../images/TechStack/DevOps/10.webp",
      "../images/TechStack/DevOps/11.webp",
      "../images/TechStack/DevOps/12.webp",
    ],
    row_4_images: [
      "../images/TechStack/DevOps/13.webp",
      "../images/TechStack/DevOps/14.webp",
      "../images/TechStack/DevOps/15.webp",
      "../images/TechStack/DevOps/16.webp",
    ],
  },

  {
    id: 1,
    row_1_images: ["../images/TechStack/DevOps/17.webp"],

    row_2_images: [],

    row_3_images: [],
    row_4_images: [],
  },
];

export const TechStackDevOpsMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/TechStack/DevOps/1.webp",
      "../images/TechStack/DevOps/2.webp",
      "../images/TechStack/DevOps/3.webp",
    ],

    row_2_images: [
      "../images/TechStack/DevOps/4.webp",
      "../images/TechStack/DevOps/5.webp",
      "../images/TechStack/DevOps/6.webp",
    ],

    row_3_images: [
      "../images/TechStack/DevOps/7.webp",
      "../images/TechStack/DevOps/8.webp",
      "../images/TechStack/DevOps/9.webp",
    ],
    row_4_images: [
      "../images/TechStack/DevOps/10.webp",
      "../images/TechStack/DevOps/11.webp",
      "../images/TechStack/DevOps/12.webp",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "../images/TechStack/DevOps/13.webp",
      "../images/TechStack/DevOps/14.webp",
      "../images/TechStack/DevOps/15.webp",
    ],

    row_2_images: [
      "../images/TechStack/DevOps/16.webp",
      "../images/TechStack/DevOps/17.webp",
    ],

    row_3_images: [],
    row_4_images: [],
  },
];

export const Awards = [
  {
    id: 0,
    row_1_images: [
      "../images/awards/slide_1/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_1/Group_1000001402.webp",
      "../images/awards/slide_1/row_1/Group_1000001405.webp",
      "../images/awards/slide_1/row_2/Group_1000001412.webp",
      "../images/awards/slide_1/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_2/Group_1000001411.webp",
      "../images/awards/slide_1/row_2/Group_1000001405.webp",
      "../images/awards/slide_1/row_2/Group_1000001409.webp",
      "../images/awards/slide_1/row_2/Group_1000001410.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/awards/slide_2/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_1/Group_1000001406.webp",
      "../images/awards/slide_2/row_1/Group_1000001405.webp",
      "../images/awards/slide_2/row_1/Group_1000001404.webp",
      "../images/awards/slide_2/row_1/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_2/row_1/Group_1000001402.webp",
      "../images/awards/slide_2/row_2/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001404.webp",
      "../images/awards/slide_2/row_2/Group_1000001406.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/awards/slide_3/row_1/Group_1000001407.webp",
      "../images/awards/slide_3/row_2/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001402.webp",

      "../images/awards/slide_3/row_2/Group_1000001407.webp",
      "../images/awards/slide_3/row_2/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_2/row_2/Group_1000001402.webp",
      "../images/awards/slide_2/row_2/Group_1000001405.webp",
      "../images/awards/slide_3/row_1/Group_1000001405.webp",
      "../images/awards/slide_3/row_1/Group_1000001404.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/awards/slide_4/row_2/Group_1000001407.webp",

      "../images/awards/slide_3/row_2/Group_1000001406.webp",
      "../images/awards/slide_3/row_2/Group_1000001402.webp",
      "../images/awards/slide_4/row_1/Group_1000001407.webp",
      "../images/awards/slide_4/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_1/Group_1000001404.webp",
      "../images/awards/slide_4/row_1/Group_1000001406.webp",
      "../images/awards/slide_4/row_1/Group_1000001402.webp",
      "../images/awards/slide_3/row_1/Group_1000001406.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/awards/slide_4/row_2/Group_1000001405.webp",
      "../images/awards/slide_4/row_2/Group_1000001408.webp",
      "../images/awards/slide_4/row_2/Group_1000001409.webp",
      "../images/awards/slide_4/row_2/Group_1000001402.webp",
      "../images/awards/slide_5/row_1/Group_1000001407.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_1/Group_1000001405.webp",
      "../images/awards/slide_5/row_1/Group_1000001404.webp",
      "../images/awards/slide_5/row_1/Group_1000001406.webp",
      "../images/awards/slide_5/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "../images/awards/slide_5/row_2/Group_1000001407.webp",
      "../images/awards/slide_5/row_2/Group_1000001405.webp",
      "../images/awards/slide_5/row_2/Group_1000001408.webp",
      "../images/awards/slide_5/row_2/Group_1000001409.webp",
      "../images/awards/slide_5/row_2/Group_1000001402.webp",
    ],

    row_2_images: [
      "../images/awards/slide_6/row_1/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001404.webp",
      "../images/awards/slide_6/row_1/Group_1000001405.webp",
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
    ],
  },

  {
    id: 6,
    row_1_images: [
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
      "../images/awards/slide_6/row_2/Group_1000001407.webp",
      "../images/awards/slide_6/row_2/Group_1000001405.webp",
      "../images/awards/slide_6/row_2/Group_1000001408.webp",
      "../images/awards/slide_6/row_2/Group_1000001409.webp",
    ],

    row_2_images: [],
  },
];

export const AwardsTablet = [
  {
    id: 0,
    row_1_images: [
      "../images/awards/slide_1/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_1/Group_1000001405.webp",
      "../images/awards/slide_1/row_1/Group_1000001404.webp",
      "../images/awards/slide_1/row_1/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_1/Group_1000001402.webp",
      "../images/awards/slide_2/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_2/Group_1000001412.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/awards/slide_1/row_2/Group_1000001409.webp",
      "../images/awards/slide_1/row_2/Group_1000001410.webp",
      "../images/awards/slide_1/row_2/Group_1000001411.webp",
      "../images/awards/slide_1/row_2/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_2/row_1/Group_1000001405.webp",
      "../images/awards/slide_2/row_1/Group_1000001404.webp",
      "../images/awards/slide_2/row_1/Group_1000001406.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/awards/slide_2/row_1/Group_1000001402.webp",
      "../images/awards/slide_2/row_2/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001404.webp",
      "../images/awards/slide_2/row_2/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_1/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001402.webp",
      "../images/awards/slide_2/row_2/Group_1000001405.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/awards/slide_3/row_2/Group_1000001407.webp",
      "../images/awards/slide_3/row_2/Group_1000001405.webp",
      "../images/awards/slide_3/row_2/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001402.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_1/Group_1000001405.webp",
      "../images/awards/slide_3/row_1/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001406.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/awards/slide_3/row_2/Group_1000001406.webp",
      "../images/awards/slide_3/row_2/Group_1000001402.webp",
      "../images/awards/slide_4/row_1/Group_1000001407.webp",
      "../images/awards/slide_4/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_1/Group_1000001404.webp",
      "../images/awards/slide_4/row_1/Group_1000001406.webp",
      "../images/awards/slide_4/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "../images/awards/slide_4/row_2/Group_1000001407.webp",
      "../images/awards/slide_4/row_2/Group_1000001405.webp",
      "../images/awards/slide_4/row_2/Group_1000001408.webp",
      "../images/awards/slide_4/row_2/Group_1000001409.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_2/Group_1000001402.webp",
      "../images/awards/slide_5/row_1/Group_1000001407.webp",
      "../images/awards/slide_5/row_1/Group_1000001405.webp",
    ],
  },

  {
    id: 6,
    row_1_images: [
      "../images/awards/slide_5/row_1/Group_1000001404.webp",
      "../images/awards/slide_5/row_1/Group_1000001406.webp",
      "../images/awards/slide_5/row_1/Group_1000001402.webp",
      "../images/awards/slide_5/row_2/Group_1000001407.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_2/Group_1000001405.webp",
      "../images/awards/slide_5/row_2/Group_1000001408.webp",
      "../images/awards/slide_5/row_2/Group_1000001409.webp",
    ],
  },

  {
    id: 7,
    row_1_images: [
      "../images/awards/slide_5/row_2/Group_1000001402.webp",
      "../images/awards/slide_6/row_1/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001404.webp",
      "../images/awards/slide_6/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
      "../images/awards/slide_6/row_2/Group_1000001407.webp",
    ],
  },

  {
    id: 8,
    row_1_images: [
      "../images/awards/slide_6/row_2/Group_1000001405.webp",
      "../images/awards/slide_6/row_2/Group_1000001408.webp",
      "../images/awards/slide_6/row_2/Group_1000001409.webp",
    ],

    row_2_images: [],
  },
];

export const AwardsMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/awards/slide_1/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_1/Group_1000001405.webp",
      "../images/awards/slide_1/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_1/Group_1000001406.webp",
      "../images/awards/slide_1/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/awards/slide_1/row_2/Group_1000001405.webp",
      "../images/awards/slide_1/row_2/Group_1000001412.webp",
      "../images/awards/slide_1/row_2/Group_1000001409.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_2/Group_1000001410.webp",
      "../images/awards/slide_1/row_2/Group_1000001411.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/awards/slide_2/row_1/Group_1000001407.webp",
      "../images/awards/slide_2/row_1/Group_1000001405.webp",
      "../images/awards/slide_2/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_2/row_1/Group_1000001406.webp",
      "../images/awards/slide_2/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/awards/slide_2/row_2/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001404.webp",
      "../images/awards/slide_2/row_2/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_1/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001402.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/awards/slide_2/row_2/Group_1000001405.webp",
      "../images/awards/slide_3/row_2/Group_1000001407.webp",
      "../images/awards/slide_3/row_2/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_2/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "../images/awards/slide_3/row_1/Group_1000001405.webp",
      "../images/awards/slide_3/row_1/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_2/Group_1000001406.webp",
      "../images/awards/slide_3/row_2/Group_1000001402.webp",
    ],
  },

  {
    id: 6,
    row_1_images: [
      "../images/awards/slide_4/row_1/Group_1000001407.webp",
      "../images/awards/slide_4/row_1/Group_1000001405.webp",
      "../images/awards/slide_4/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_1/Group_1000001406.webp",
      "../images/awards/slide_4/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 7,
    row_1_images: [
      "../images/awards/slide_4/row_2/Group_1000001407.webp",
      "../images/awards/slide_4/row_2/Group_1000001405.webp",
      "../images/awards/slide_4/row_2/Group_1000001408.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_2/Group_1000001409.webp",
      "../images/awards/slide_4/row_2/Group_1000001402.webp",
    ],
  },

  {
    id: 8,
    row_1_images: [
      "../images/awards/slide_5/row_1/Group_1000001407.webp",
      "../images/awards/slide_5/row_1/Group_1000001405.webp",
      "../images/awards/slide_5/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_1/Group_1000001406.webp",
      "../images/awards/slide_5/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 9,
    row_1_images: [
      "../images/awards/slide_5/row_2/Group_1000001407.webp",
      "../images/awards/slide_5/row_2/Group_1000001405.webp",
      "../images/awards/slide_5/row_2/Group_1000001408.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_2/Group_1000001409.webp",
      "../images/awards/slide_5/row_2/Group_1000001402.webp",
    ],
  },
  {
    id: 10,
    row_1_images: [
      "../images/awards/slide_6/row_1/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001404.webp",
      "../images/awards/slide_6/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 11,
    row_1_images: [
      "../images/awards/slide_6/row_2/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
    ],

    row_2_images: ["../images/awards/slide_6/row_2/Group_1000001407.webp"],
  },
];

export const clienteleData = [
  {
    id: 0,
    row_1_images: [
      "../images/clientele/Logos/1.webp",
      "../images/clientele/Logos/2.webp",
      "../images/clientele/Logos/3.webp",
      "../images/clientele/Logos/4.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/5.webp",
      "../images/clientele/Logos/6.webp",
      "../images/clientele/Logos/7.webp",
      "../images/clientele/Logos/8.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/clientele/Logos/9.webp",
      "../images/clientele/Logos/10.webp",
      "../images/clientele/Logos/11.webp",
      "../images/clientele/Logos/12.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/13.webp",
      "../images/clientele/Logos/14.webp",
      "../images/clientele/Logos/15.webp",
      "../images/clientele/Logos/16.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/clientele/Logos/17.webp",
      "../images/clientele/Logos/18.webp",
      "../images/clientele/Logos/19.webp",
      "../images/clientele/Logos/20.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/21.webp",
      "../images/clientele/Logos/22.webp",
      "../images/clientele/Logos/23.webp",
      "../images/clientele/Logos/24.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/Logos/25.webp",
      "../images/clientele/Logos/26.webp",
      "../images/clientele/Logos/27.webp",
      "../images/clientele/Logos/28.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/29.webp",
      "../images/clientele/Logos/30.webp",
      "../images/clientele/Logos/31.webp",
      "../images/clientele/Logos/32.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/clientele/Logos/33.webp",
      "../images/clientele/Logos/34.webp",
      "../images/clientele/Logos/35.webp",
      "../images/clientele/Logos/36.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/37.webp",
      "../images/clientele/Logos/38.webp",
      "../images/clientele/Logos/39.webp",
      "../images/clientele/Logos/40.webp",
    ],
  },
  {
    id: 5,
    row_1_images: [
      "../images/clientele/Logos/41.webp",
      "../images/clientele/Logos/42.webp",
    ],
    row_2_images: [],
  },
];

export const clienteleTabletData = [
  {
    id: 0,
    row_1_images: [
      "../images/clientele/Logos/1.webp",
      "../images/clientele/Logos/2.webp",
      "../images/clientele/Logos/3.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/4.webp",
      "../images/clientele/Logos/5.webp",
      "../images/clientele/Logos/6.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/clientele/Logos/7.webp",
      "../images/clientele/Logos/8.webp",
      "../images/clientele/Logos/9.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/10.webp",
      "../images/clientele/Logos/11.webp",
      "../images/clientele/Logos/12.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/clientele/Logos/13.webp",
      "../images/clientele/Logos/14.webp",
      "../images/clientele/Logos/15.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/16.webp",
      "../images/clientele/Logos/17.webp",
      "../images/clientele/Logos/18.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/Logos/19.webp",
      "../images/clientele/Logos/20.webp",
      "../images/clientele/Logos/21.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/22.webp",
      "../images/clientele/Logos/23.webp",
      "../images/clientele/Logos/24.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/clientele/Logos/25.webp",
      "../images/clientele/Logos/26.webp",
      "../images/clientele/Logos/27.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/28.webp",
      "../images/clientele/Logos/29.webp",
      "../images/clientele/Logos/30.webp",
    ],
  },
  {
    id: 5,
    row_1_images: [
      "../images/clientele/Logos/31.webp",
      "../images/clientele/Logos/32.webp",
      "../images/clientele/Logos/33.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/34.webp",
      "../images/clientele/Logos/35.webp",
      "../images/clientele/Logos/36.webp",
    ],
  },
  {
    id: 6,
    row_1_images: [
      "../images/clientele/Logos/37.webp",
      "../images/clientele/Logos/38.webp",
      "../images/clientele/Logos/39.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/40.webp",
      "../images/clientele/Logos/41.webp",
      "../images/clientele/Logos/42.webp",
    ],
  },
];

export const clienteleMobileData = [
  {
    id: 0,
    row_1_images: [
      "../images/clientele/Logos/1.webp",
      "../images/clientele/Logos/2.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/3.webp",
      "../images/clientele/Logos/4.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/clientele/Logos/5.webp",
      "../images/clientele/Logos/6.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/7.webp",
      "../images/clientele/Logos/8.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/clientele/Logos/9.webp",
      "../images/clientele/Logos/10.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/11.webp",
      "../images/clientele/Logos/12.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/Logos/13.webp",
      "../images/clientele/Logos/14.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/15.webp",
      "../images/clientele/Logos/16.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/clientele/Logos/17.webp",
      "../images/clientele/Logos/18.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/19.webp",
      "../images/clientele/Logos/20.webp",
    ],
  },
  {
    id: 5,
    row_1_images: [
      "../images/clientele/Logos/21.webp",
      "../images/clientele/Logos/22.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/23.webp",
      "../images/clientele/Logos/24.webp",
    ],
  },
  {
    id: 6,
    row_1_images: [
      "../images/clientele/Logos/25.webp",
      "../images/clientele/Logos/26.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/27.webp",
      "../images/clientele/Logos/28.webp",
    ],
  },
  {
    id: 7,
    row_1_images: [
      "../images/clientele/Logos/29.webp",
      "../images/clientele/Logos/30.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/31.webp",
      "../images/clientele/Logos/32.webp",
    ],
  },
  {
    id: 8,
    row_1_images: [
      "../images/clientele/Logos/33.webp",
      "../images/clientele/Logos/34.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/35.webp",
      "../images/clientele/Logos/36.webp",
    ],
  },
  {
    id: 9,
    row_1_images: [
      "../images/clientele/Logos/37.webp",
      "../images/clientele/Logos/38.webp",
    ],
    row_2_images: [
      "../images/clientele/Logos/39.webp",
      "../images/clientele/Logos/40.webp",
    ],
  },
  {
    id: 10,
    row_1_images: [
      "../images/clientele/Logos/41.webp",
      "../images/clientele/Logos/42.webp",
    ],
    row_2_images: [],
  },
];



export const testimonialData = [
  {
    quote: "testimonial.quote1",
    text: "testimonial.text1",
    logo: "../images/testimonial/pareit.webp",
    location: "testimonial.location1",
    industry: "testimonial.industry1",
  },
  {
    quote: "testimonial.quote2",
    text: "testimonial.text2",
    logo: "../images/testimonial/actiontoyrental.webp",
    location: "testimonial.location2",
    industry: "testimonial.industry2",
  },
  {
    quote: "testimonial.quote3",
    text: "testimonial.text3",
    logo: "../images/testimonial/doganai.webp",
    location: "testimonial.location3",
    industry: "testimonial.industry3",
  },
  {
    quote: "testimonial.quote4",
    text: "testimonial.text4",
    logo: "../images/testimonial/nok.webp",
    location: "testimonial.location4",
    industry: "testimonial.industry4",
  },
  {
    quote: "testimonial.quote5",
    text: "testimonial.text5",
    logo: "../images/testimonial/parasinterior.webp",
    location: "testimonial.location5",
    industry: "testimonial.industry5",
  },
  {
    quote: "testimonial.quote6",
    text: "testimonial.text6",
    logo: "../images/testimonial/mobiloutdoormedia.webp",
    location: "testimonial.location6",
    industry: "testimonial.industry6",
  },
  {
    quote: "testimonial.quote7",
    text: "testimonial.text7",
    logo: "../images/testimonial/solarisfinance.webp",
    location: "testimonial.location7",
    industry: "testimonial.industry7",
  },
  {
    quote: "testimonial.quote8",
    text: "testimonial.text8",
    logo: "../images/testimonial/tailorspoint.webp",
    location: "testimonial.location8",
    industry: "testimonial.industry8",
  },
  {
    quote: "testimonial.quote9",
    text: "testimonial.text9",
    logo: "../images/testimonial/vvictortechnology.webp",
    location: "testimonial.location9",
    industry: "testimonial.industry9",
  },
  {
    quote: "testimonial.quote10",
    text: "testimonial.text10",
    logo: "../images/testimonial/valunova.webp",
    location: "testimonial.location10",
    industry: "testimonial.industry10",
  },
  {
    quote: "testimonial.quote11",
    text: "testimonial.text11",
    logo: "../images/testimonial/wetals.webp",
    location: "testimonial.location11",
    industry: "testimonial.industry11",
  },
  {
    quote: "testimonial.quote12",
    text: "testimonial.text12",
    logo: "../images/testimonial/weteams1.webp",
    location: "testimonial.location12",
    industry: "testimonial.industry12",
  },
  {
    quote: "testimonial.quote13",
    text: "testimonial.text13",
    logo: "../images/testimonial/supersourcing.webp",
    location: "testimonial.location13",
    industry: "testimonial.industry13",
  },
  {
    quote: "testimonial.quote14",
    text: "testimonial.text14",
    logo: "../images/testimonial/sumtracker.webp",
    location: "testimonial.location14",
    industry: "testimonial.industry14",
  },
  {
    quote: "testimonial.quote15",
    text: "testimonial.text15",
    logo: "../images/testimonial/bakeri.webp",
    location: "testimonial.location15",
    industry: "testimonial.industry15",
  },
];
export const achivementsData = [
  {
    text: "achivements.text1",
    btext: "achivements.btext1",
    img: "up_gov_w.svg",
  },
  {
    text: "achivements.text2",
    btext: "achivements.btext2",
    img: "gujyoga.svg",
  },
  {
    text: "achivements.text3",
    btext: "achivements.btext3",
    img: "ahimsa.svg",
  },
  {
    text: "achivements.text4",
    btext: "achivements.btext4",
    img: "dron.svg",
  },
  {
    text: "achivements.text5",
    btext: "achivements.btext5",
    img: "nursury.svg",
  },
  {
    text: "achivements.text6",
    btext: "achivements.btext6",
    img: "newIndia.svg",
  },
  {
    text: "achivements.text7",
    btext: "achivements.btext7",
    img: "newIndia.svg",
  },
];
export const BusinessVerticalsCards = [
  {
    title: "industries.title1",
    description: "industries.description1",
    image: "../images/business-verticals/Frame_1000002780.webp",
  },
  {
    title: "industries.title2",
    description: "industries.description2",
    image: "../images/business-verticals/Frame_1000002780-1.webp",
  },
  {
    title: "industries.title3",
    description: "industries.description3",
    image: "../images/business-verticals/Frame_1000002780-2.webp",
  },
  {
    title: "industries.title4",
    description: "industries.description4",
    image: "../images/business-verticals/Frame_1000002780-3.webp",
  },
  {
    title: "industries.title5",
    description: "industries.description5",
    image: "../images/business-verticals/Frame_1000002780-4.webp",
  },
  {
    title: "industries.title6",
    description: "industries.description6",
    image: "../images/business-verticals/Frame_1000002780-5.webp",
  },
  {
    title: "industries.title7",
    description: "industries.description7",
    image: "../images/business-verticals/Frame_1000002780-6.webp",
  },
  {
    title: "industries.title8",
    description: "industries.description8",
    image: "../images/business-verticals/Frame_1000002780-7.webp",
  },
];
export const BusinessVerticalsTabletCards = [
  {
    row_1_images: [
      {
        title: "industries.title1",
        description: "industries.description1",
        image: "../images/business-verticals/Frame_1000002780.webp",
      },
      {
        title: "industries.title2",
        description: "industries.description2",
        image: "../images/business-verticals/Frame_1000002780-1.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title3",
        description: "industries.description3",
        image: "../images/business-verticals/Frame_1000002780-2.webp",
      },
      {
        title: "industries.title4",
        description: "industries.description4",
        image: "../images/business-verticals/Frame_1000002780-3.webp",
      },
    ],
  },
  {
    row_1_images: [
      {
        title: "industries.title5",
        description: "industries.description5",
        image: "../images/business-verticals/Frame_1000002780-4.webp",
      },
      {
        title: "industries.title6",
        description: "industries.description6",
        image: "../images/business-verticals/Frame_1000002780-5.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title7",
        description: "industries.description7",
        image: "../images/business-verticals/Frame_1000002780-6.webp",
      },
      {
        title: "industries.title8",
        description: "industries.description8",
        image: "../images/business-verticals/Frame_1000002780-7.webp",
      },
    ],
  },
];

export const AreaExperticsData = [
  {
    id: 1,
    title: "areaexpertise.AI",
    cards: [
      {
        title: "areaexpertise.ai1",
        image: "../images/area-experties/AI/ai.webp",
      },
      {
        title: "areaexpertise.ai2",
        image: "../images/area-experties/AI/ml.webp",
      },
      {
        title: "areaexpertise.ai3",
        image: "../images/area-experties/AI/data-science.webp",
      },
      {
        title: "areaexpertise.ai4",
        image: "../images/area-experties/AI/deep-learning.webp",
      },
      {
        title: "areaexpertise.ai5",
        image: "../images/area-experties/AI/nlp.webp",
      },
      {
        title: "areaexpertise.ai6",
        image: "../images/area-experties/AI/cv.webp",
      },
    ],
  },
  {
    id: 2,
    title: "areaexpertise.GenAI",
    cards: [
      {
        title: "areaexpertise.genai1",
        image: "../images/area-experties/GEN_AI/llm.webp",
      },
      {
        title: "areaexpertise.genai2",
        image: "../images/area-experties/GEN_AI/sd.webp",
      },
      {
        title: "areaexpertise.genai3",
        image: "../images/area-experties/GEN_AI/lc.webp",
      },
      {
        title: "areaexpertise.genai4",
        image: "../images/area-experties/GEN_AI/lg.webp",
      },
      {
        title: "areaexpertise.genai5",
        image: "../images/area-experties/GEN_AI/li.webp",
      },
      {
        title: "areaexpertise.genai6",
        image: "../images/area-experties/GEN_AI/ag.webp",
      },
    ],
  },
  {
    id: 3,
    title: "areaexpertise.Development",
    cards: [
      {
        title: "areaexpertise.dev1",
        image: "../images/area-experties/DEVELOPMENT/nl.webp",
      },
      {
        title: "areaexpertise.dev2",
        image: "../images/area-experties/DEVELOPMENT/wd.webp",
      },
      {
        title: "areaexpertise.dev3",
        image: "../images/area-experties/DEVELOPMENT/bd.webp",
      },
      {
        title: "areaexpertise.dev4",
        image: "../images/area-experties/DEVELOPMENT/csd.webp",
      },
      {
        title: "areaexpertise.dev5",
        image: "../images/area-experties/DEVELOPMENT/cpa.webp",
      },
      {
        title: "areaexpertise.dev6",
        image: "../images/area-experties/DEVELOPMENT/wgd.webp",
      },
    ],
  },
  {
    id: 4,
    title: "areaexpertise.Deployment",
    cards: [
      {
        title: "areaexpertise.dep1",
        image: "../images/area-experties/DEPLOYMENT/ku.webp",
      },
      {
        title: "areaexpertise.dep2",
        image: "../images/area-experties/DEPLOYMENT/arc.webp",
      },
      {
        title: "areaexpertise.dep3",
        image: "../images/area-experties/DEPLOYMENT/dc.webp",
      },
      {
        title: "areaexpertise.dep4",
        image: "../images/area-experties/DEPLOYMENT/cicd.webp",
      },
      {
        title: "areaexpertise.dep5",
        image: "../images/area-experties/DEPLOYMENT/iaac.webp",
      },
      {
        title: "areaexpertise.dep6",
        image: "../images/area-experties/DEPLOYMENT/cp.webp",
      },
    ],
  },
];

export const productsData = [
  {
    id: 1,
    title: "products.product1_title",
    sub_title: "products.product1_subtitle",
    description_1: "products.product1_desc1",
    description_2: "products.product1_desc2",
    product_image: "../images/products/Haveto_Logo.webp",
    explore_url: "https://haveto.com/",
  },
  {
    id: 2,
    title: "products.product3_title",
    sub_title: "products.product3_subtitle",
    description_1: "products.product3_desc1",
    description_2: "products.product3_desc2",
    product_image: "../images/products/fx_chat_logo.webp",
    explore_url: "https://fxchat.htree.plus/",
  },
  {
    id: 3,
    title: "products.product2_title",
    sub_title: "products.product2_subtitle",
    description_1: "products.product2_desc1",
    description_2: "products.product2_desc2",
    product_image: "../images/products/AI_Coder.webp",
  },
  {
    id: 4,
    title: "products.product4_title",
    sub_title: "products.product4_subtitle",
    description_1: "products.product4_desc1",
    description_2: "products.product4_desc2",
    product_image: "../images/products/AITutor.webp",
    explore_url: "https://aitutor.htree.plus/",
  },
];
export const servicesData = [
  {
    id: 0,
    cardTitle: "services.card1",
    cardDesc: "services.desc1",
    // modalTitleId: "project-outsourcing-modal-title-vcenter",
    // modalTitle: "projectoutsourcing.title",
    // modalComponent: <ProjectOutsourcing />,
  },
  {
    id: 1,
    cardTitle: "services.card2",
    cardDesc: "services.desc2",
    // modalTitleId: "on-demand-developer-modal-title-vcenter",
    // modalTitle: "ondemanddev.title",
    // modalComponent: <OnDemandDe />,
  },
  {
    id: 2,
    cardTitle: "services.card3",
    cardDesc: "services.desc3",
    // modalTitleId: "tech-and-talent-modal-title-vcenter",
    // modalTitle: "techandtalent.title",
    // modalComponent: <TechandTalent />,
  },
];

export const successimage = {
  image: "../images/success_image.webp",
};

export const navbarheaderlinks = [
  {
    id: 0,
    title: "navbarlinks.title1",
    links: [
      {
        link_title: "navbarlinks.title1_link1",
        link_id: "clientele",
      },
      {
        link_title: "navbarlinks.title1_link2",
        link_id: "hire-ex-experts",
      },
      {
        link_title: "navbarlinks.title1_link3",
        link_id: "testimonial",
      },
    ],
  },
  {
    id: 1,
    title: "navbarlinks.title2",
    links: [
      {
        link_title: "navbarlinks.title2_link1",
        link_id: "techstack",
      },
      {
        link_title: "navbarlinks.title2_link2",
        link_id: "areaexpertise",
      },
    ],
  },
  {
    id: 2,
    title: "navbarlinks.title3",
    links: [
      {
        link_title: "navbarlinks.title3_link1",
        link_id: "gwr",
      },
      {
        link_title: "navbarlinks.title3_link2",
        link_id: "awards",
      },
    ],
  },
  {
    id: 3,
    title: "navbarlinks.title4",
    links: [
      {
        link_title: "navbarlinks.title4_link1",
        link_id: "services",
      },
      {
        link_title: "navbarlinks.title4_link2",
        link_id: "industry",
      },
    ],
  },
  {
    id: 4,
    title: "navbarlinks.title5",
    links: [
      {
        link_title: "navbarlinks.title5_link1",
        link_id: "product-1",
      },
      {
        link_title: "navbarlinks.title5_link2",
        link_id: "product-2",
      },
      {
        link_title: "navbarlinks.title5_link3",
        link_id: "product-3",
      },
      {
        link_title: "navbarlinks.title5_link4",
        link_id: "product-4",
      },
    ],
  },
  // {
  //   id: 5,
  //   title: "navbarlinks.title6",
  //   links: [
  //     {
  //       link_title: "navbarlinks.title6_link1",
  //       link_id: "/",
  //     },
  //     {
  //       link_title: "navbarlinks.title6_link2",
  //       link_id: "/",
  //     },
  //   ],
  // },
  {
    id: 6,
    title: "navbarlinks.title7",
    title_link: "https://fxis.ai/edu/",
  },
  {
    id: 7,
    title: "navbarlinks.title8",
    title_link: "contact",
  },
];
