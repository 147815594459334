import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';

// Lazy loaded components
const Clientele = lazy(() => import('./AboutSection/Clientele'));
const HireExExperts = lazy(() => import('./HireExExpertSection/HireExExperts'));
const Testimonial = lazy(() => import('./TestimonySection/Testimonial'));
const ServiceSection = lazy(() => import('./ServiceSection/ServiceSection'));
const Awards = lazy(() => import('./AwardSection/Awards'));
const GwrSection = lazy(() => import('./GwrSection/GwrSection'));
const Products = lazy(() => import('./ProductSection/Products'));
const Info = lazy(() => import('./Info'));
const Footer = lazy(() => import('./FooterSection/Footer'));

// Skeleton loader component
const SkeletonLoader = React.memo(() => (
  <div className="skeleton-loader">
    <div className="skeleton-line"></div>
    <div className="skeleton-line"></div>
    <div className="skeleton-line"></div>
  </div>
));

// Memoized components
const MemoizedClientele = React.memo(Clientele);
const MemoizedHireExExperts = React.memo(HireExExperts);
const MemoizedTestimonial = React.memo(Testimonial);
const MemoizedServiceSection = React.memo(ServiceSection);
const MemoizedAwards = React.memo(Awards);
const MemoizedGwrSection = React.memo(GwrSection);
const MemoizedProducts = React.memo(Products);
const MemoizedInfo = React.memo(Info);
const MemoizedFooter = React.memo(Footer);

const OnDemandLoader = () => {
  const [loadedComponents, setLoadedComponents] = useState({
    Clientele: true,
    HireExExperts: true,
  });
  const componentRefs = useRef([]);

  const components = [
    { name: 'Clientele', Component: MemoizedClientele },
    { name: 'HireExExperts', Component: MemoizedHireExExperts },
    { name: 'Testimonial', Component: MemoizedTestimonial },
    { name: 'ServiceSection', Component: MemoizedServiceSection },
    { name: 'Awards', Component: MemoizedAwards },
    { name: 'GwrSection', Component: MemoizedGwrSection },
    { name: 'Products', Component: MemoizedProducts },
    { name: 'Info', Component: MemoizedInfo },
    { name: 'Footer', Component: MemoizedFooter },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const name = entry.target.dataset.name;
            setLoadedComponents((prev) => ({ ...prev, [name]: true }));
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '200px', threshold: 0.1 }
    );

    componentRefs.current.forEach((ref, index) => {
      if (ref && index > 1) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      {components.map(({ name, Component }, index) => (
        <div
          key={name}
          ref={(el) => (componentRefs.current[index] = el)}
          data-name={name}
        >
          <Suspense fallback={<SkeletonLoader />}>
            {loadedComponents[name] ? <Component /> : <SkeletonLoader />}
          </Suspense>
        </div>
      ))}
    </>
  );
};

export default OnDemandLoader;