import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import appStore from './redux/store/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

render(
  <Provider store={appStore}>
    <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
